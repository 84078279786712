import { FC, lazy, Suspense, useEffect, useMemo, useState } from 'react';
import useLocalised from 'customHooks/useLocalised/useLocalised'; // will use when final copy is ready
import { elementIds } from 'data/constants';
import img1 from 'assets/homeWhyLE/homeWhyLE_img1.webp';
import img2 from 'assets/homeWhyLE/homeWhyLE_img2.webp';
import img3 from 'assets/homeWhyLE/homeWhyLE_img3.webp';
import { useMediaQuery } from '@mui/material';
import LogoXl from 'assets/svg/whyLittleEmperors_logo_xl.svg';
import LogoDesktop from 'assets/svg/whyLittleEmperors_logo_desktop.svg';
import LogoTablet from 'assets/svg/whyLittleEmperors_logo_tablet.svg';
import LogoMobile from 'assets/svg/whyLittleEmperors_logo_mobile.svg';
import Image from 'next/image';


const WhyLittleEmperors: FC = () => {
  const isMobile = useMediaQuery('(max-width:1024px)');
  const isTablet = useMediaQuery('(max-width:1280px)');
  const isDesktop = useMediaQuery('(max-width:1650px)');
  return (
    <div className="why-little-emperors" id={elementIds.WHY_LITTLE_EMPERORS_SECTION_ID}>
      <div className="why-little-emperors__top">
        <div className="why-little-emperors__title">
          <div className="why-little-emperors__title__logo">
            {isMobile ? (
              <LogoMobile />
            ) : isTablet ? (
              <LogoTablet />
            ) : isDesktop ? (
              <LogoDesktop />
            ) : (
              <LogoXl />
            )}
          </div>
          <div className="why-little-emperors__title__text">
            <p>
              Upgrade your travel experience with our members-only club,
              promising exclusive benefits and the lowest rates at the very
              best hotels around the globe.
            </p>
          </div>
        </div>
      </div>
      <div className="why-little-emperors__bottom">
        <div className='why-little-emperors__benefits'>
          
          <div className='why-little-emperors__benefits__item'>
            <div className='why-little-emperors__benefits__img'>
              <Image src={img1} alt='' layout="fill" objectFit="cover" />
            </div>
            <div className='why-little-emperors__benefits__text'>
              <h3>Access the best prices</h3>
              <p>Sleep easy knowing you’re guaranteed the best prices at more than 4,000 luxury hotels worldwide, with preferential rates on half the collection.</p>
            </div>
          </div>
          
          <div className='why-little-emperors__benefits__item'>
            <div className='why-little-emperors__benefits__img'>
              <Image src={img2} alt='' layout="fill" objectFit="cover" />
            </div>
            <div className='why-little-emperors__benefits__text'>
              <h3>Enjoy complimentry benefits</h3>
              <p>We only present our members with real benefits, like complimentary breakfasts, hotel credits, confirmed upgrades and add-on experiences.</p>
            </div>
          </div>
          
          <div className='why-little-emperors__benefits__item'>
            <div className='why-little-emperors__benefits__img'>
              <Image src={img3} alt='' layout="fill" objectFit="cover" />
            </div>
            <div className='why-little-emperors__benefits__text'>
              <h3>Relax and switch off</h3>
              <p>No small print here. Your booking has the same cancellation, payment and loyalty-point collection as when you book with hotels directly.</p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default WhyLittleEmperors;
