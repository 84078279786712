import { FC } from 'react';
import Image from 'next/image';
import imgMylers from 'assets/HomeMyler/mylers.webp';
import Link from 'next/link';
import Button from 'components/Button/BaseButton';
import styles from './MylerHome.module.scss';

const MylerHome: FC = () => {
  return (
    <div className={styles['mylerhome']}>
      <div className={styles['mylerhome__image']}>
        <Image
          src={imgMylers}
          alt="Discover MyLER"
          layout="fill"
          objectFit="cover"
        />
      </div>
      <div className={styles['mylerhome__content']}>
        <div className={styles['mylerhome__txt--1']}>
          You’re here because you love luxury travel
        </div>
        <div className={styles['mylerhome__txt--2']}>
          So, why not turn it into a career?
        </div>
        <div className={styles['mylerhome__txt--3']}>
          &nbsp;Whether it’s a full-time business or a lucrative
          side-hustle,&nbsp;
          <br className={styles['hide--tabletSmall']} />
          &nbsp;our Independent Consultant programme lets you earn income&nbsp;
          <br className={styles['hide--tabletSmall']} />
          while working from anywhere, anytime.
        </div>
        <div className={styles['mylerhome__cta']}>
          <Link href="/discover-myler">
            <a>
              <Button variant={'primary-black'}>Learn more</Button>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MylerHome;
