import React from 'react';
import Image from 'next/image';
import imgMembership from 'assets/MembershipGiftCards/membership.webp';
import imgGiftCards from 'assets/MembershipGiftCards/giftcards.png';
import SVGShareTheJoy from 'assets/MembershipGiftCards/ShareTheJoy.svg';
import Link from "next/link";
import BaseButton from 'components/Button/BaseButton';
import styles from "./MembershipGiftCards.module.scss";

const MembershipGiftCards: React.FC = () => {

    return (
        <div className={styles['membership-gift-cards']}>
            <div className={styles['membership-gift-cards__wrapper']}>
                <div
                    className={`${styles['membership-gift-cards__block']} ${styles['membership-gift-cards__block--membership']}`}
                >
                    <div className={styles['membership-gift-cards__image']}>
                        <Image src={imgMembership} alt="" layout="fill" objectFit="cover" />
                    </div>
                    <div className={styles['membership-gift-cards__title']}>
                        <div className={styles['membership-gift-cards__desktopCopy']}>
                            <p>Join our exclusive hotel club</p>
                            <h3>Membership</h3>
                        </div>
                        <div className={styles['membership-gift-cards__mobileCopy']}>
                            <h3>
                                Join our exclusive hotel
                                <br />
                                club membership
                            </h3>
                        </div>
                        <div className={styles['membership-gift-cards__cta']}>
                            <Link href="/membership">
                                <a>
                                    <BaseButton variant={'primary-gold'}>View memberships</BaseButton>
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
                <div
                    className={`${styles['membership-gift-cards__block']} ${styles['membership-gift-cards__block--gift']}`}
                >
                    <div className={styles['membership-gift-cards__card']}>
                        <Image src={imgGiftCards} alt="" layout="fill" objectFit="cover" />
                    </div>
                    <div className={styles['membership-gift-cards__strap']}>
                        <SVGShareTheJoy viewBox="0 0 135 142" />
                    </div>
                    <div className={styles['membership-gift-cards__title']}>
                        <div>
                            <p>of luxury travel this season with</p>
                            <h3>
                                Little Emperors <br />
                                e-gift card
                            </h3>
                        </div>
                        <div className={styles['membership-gift-cards__cta']}>
                            <Link href="/e-gift-card">
                                <a>
                                    <BaseButton variant={'secondary-ghost'}>Buy now</BaseButton>
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembershipGiftCards;