import { FC } from 'react';
import { TestimonialType } from 'types/types';

interface ITestimonial {
  item: TestimonialType;
};

export const TestimonialItem: FC<ITestimonial> = ({
  item: {
    testimonial,
    name,
    destination,
    tier,
  }
}) => {
  return (
    <div className='slider-container'>
      <div className='slider-container__text'>{testimonial}</div>
      <div className='slider-container__line' />
      <div className='slider-container__member-and-destination'>{name} - {destination}</div>
      <div className='slider-container__membership-tier'>{tier}</div>
    </div>
  );
};
    
export default TestimonialItem;
