const QuotationMarkHome = () => {
  return (
    <div className="quotation-mark">
      <svg width="36" height="24" viewBox="0 0 36 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.09762 23.2C5.77762 23.2 4.69762 22.93 3.85762 22.39C3.01762 21.85 2.32762 21.16 1.78762 20.32C1.18762 19.3 0.767617 18.25 0.527617 17.17C0.347617 16.03 0.257617 15.1 0.257617 14.38C0.257617 11.44 1.00762 8.77 2.50762 6.37C4.00762 3.97 6.34762 2.02 9.52762 0.519994L10.3376 2.13999C8.47762 2.92 6.85762 4.15 5.47762 5.83C4.15762 7.51 3.49762 9.22 3.49762 10.96C3.49762 11.68 3.58762 12.31 3.76762 12.85C4.72762 12.07 5.83762 11.68 7.09762 11.68C8.65762 11.68 10.0076 12.19 11.1476 13.21C12.2876 14.23 12.8576 15.64 12.8576 17.44C12.8576 19.12 12.2876 20.5 11.1476 21.58C10.0076 22.66 8.65762 23.2 7.09762 23.2ZM28.2518 0.519994C29.5718 0.519994 30.6518 0.789994 31.4918 1.32999C32.3318 1.86999 33.0218 2.55999 33.5618 3.39999C34.2218 4.36 34.6418 5.41 34.8218 6.55C35.0018 7.69 35.0918 8.62 35.0918 9.34C35.0918 12.28 34.3418 14.95 32.8418 17.35C31.3418 19.75 29.0018 21.7 25.8218 23.2L25.0118 21.58C26.8718 20.8 28.4618 19.57 29.7818 17.89C31.1618 16.21 31.8518 14.5 31.8518 12.76C31.8518 12.04 31.7618 11.41 31.5818 10.87C30.6218 11.65 29.5118 12.04 28.2518 12.04C26.6918 12.04 25.3418 11.53 24.2018 10.51C23.0618 9.49 22.4918 8.08 22.4918 6.28C22.4918 4.6 23.0618 3.22 24.2018 2.13999C25.3418 1.05999 26.6918 0.519994 28.2518 0.519994Z" fill="#BAA57E" />
      </svg>
    </div>
  );
};

export default QuotationMarkHome;
