import type { NextPage, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

import GlobalPresence from 'blocks/GlobalPresence/GlobalPresence';
import DownloadApp from 'blocks/DownloadApp/DownloadApp';
import SayAboutUs from 'blocks/SayAboutUs/SayAboutUs';
import WhyLittleEmperors from 'blocks/WhyLittleEmperors/WhyLittleEmperors';
import OurHotels from 'blocks/OurHotels/OurHotels';
import Instagram from 'blocks/Instagram/Instagram';
import { IGenericPage, IHomePage } from 'types/interfaces';
import GlobalLayout from 'blocks/Layouts/GlobalLayout';
import HeroSection from 'blocks/HeroSection/HeroSection';
import TravelJournalSection from 'blocks/travel-journal-articles/home/section';
import PageService from 'services/PageService/PageService';
import { DialogueContext } from 'customHooks/useDialogue/useDialogue';
import {
  urlQueryParamKeys,
  RESET_PASSWORD_DIALOGUE,
  navigationPaths,
  PAGE_INVALIDATION_INTERVAL,
} from 'data/constants';
import HotelsCarousel from 'components/HotelsCarousel/HotelsCarousel';
import BuyVoucher from 'blocks/e-gift-card/home/BuyVoucher';
import MembershipGiftCards from "blocks/MembershipGiftCards/MembershipGiftCards";
import MylerHome from "blocks/MylerHome/MylerHome";


const Home: NextPage<IHomePage & IGenericPage> = ({
  globalLayoutProps,
  ourHotels,
  testimonials,
  heroElements,
  instagramImages,
  travelJournalArticles,
}) => {
  const router = useRouter();
  const { openDialogue } = useContext(DialogueContext);

  useEffect(() => {
    if (router.query[urlQueryParamKeys.RESET_PASSWORD_TOKEN]) {
      openDialogue(RESET_PASSWORD_DIALOGUE);
    }
  }, [openDialogue, router.query]);

  return (
    <GlobalLayout {...globalLayoutProps}>
      <HeroSection heroElements={heroElements} />
      <WhyLittleEmperors />
      <BuyVoucher />
      <OurHotels ourHotels={ourHotels} />
      <SayAboutUs testimonials={testimonials} />
      <MembershipGiftCards />
      <GlobalPresence />
      <Instagram instagramImages={instagramImages} />
      <HotelsCarousel mt={25} />
      <TravelJournalSection articles={travelJournalArticles} />
      <MylerHome />
      <DownloadApp />
    </GlobalLayout>
  );
};

export default Home;

export const getStaticProps: GetStaticProps = async props => {
  const genericPageProps = await PageService.getGenericPageProps({
    navPath: navigationPaths.HOMEPAGE,
  });
  const homePageProps = await PageService.getHomePageProps();
  return {
    props: {
      ...genericPageProps,
      ...homePageProps,
    },
    revalidate: PAGE_INVALIDATION_INTERVAL,
  };
};
