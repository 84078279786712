import { FC, useState, useEffect, useCallback, useContext } from 'react';
import {
  elementIds,
  HERO_SECTION_TIME_INTERVAL,
  JOIN_US_DIALOG_STEP_1,
  navigationPaths,
} from 'data/constants';
import { HotelImageType } from 'types/types';
import { Body, Heading } from 'components/text';
import Button from 'components/Button/BaseButton';
import { DialogueContext } from 'customHooks/useDialogue/useDialogue';
import Link from 'next/link';
import { useMediaQuery } from '@mui/material';

interface IHeroSection {
  heroElements: HotelImageType[];
  hasTextBox?: boolean;
}

type HeroSectionStateType = {
  index: number;
  hotelText: string;
};

const HeroSection: FC<IHeroSection> = ({ heroElements }) => {
  const [state, setState] = useState<HeroSectionStateType>({
    index: 0,
    hotelText: heroElements[0].hotel,
  });

  const { openDialogue } = useContext(DialogueContext);

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const handleJoinNowClick = () => {
    openDialogue(JOIN_US_DIALOG_STEP_1);
  };

  const handleScrollDown = useCallback(() => {
    const nextSection = document.getElementById(
      elementIds.WHY_LITTLE_EMPERORS_SECTION_ID
    );
    if (nextSection) {
      (nextSection as HTMLDivElement).scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  // Preloading images
  const [preloadedImageCount, setPreloadedImageCount] = useState(0);
  useEffect(() => {
    heroElements.forEach(({ image: imageUrl }) => {
      const img = new Image();

      img.onload = () => {
        setPreloadedImageCount(prev => prev + 1);
      };

      img.src = imageUrl;
    });
  }, [heroElements]);

  useEffect(() => {
    if (preloadedImageCount < heroElements.length) {
      return;
    }
    let index = 1;
    const timeInterval = setInterval(() => {
      setState({
        index,
        hotelText: heroElements[index].hotel,
      });
      index += 1;
      if (index === heroElements.length) {
        index = 0;
      }
    }, HERO_SECTION_TIME_INTERVAL);
    return () => {
      clearInterval(timeInterval);
    };
  }, [heroElements, preloadedImageCount]);

  return (
    <>
      <div className="hero-section-container">
        {heroElements.map(({ id, image }, index) => (
          <div
            key={id}
            className="hero-section-container__background-layer"
            style={{
              backgroundImage: `url("${image}")`,
              opacity: state.index === index ? 1 : 0,
            }}
          />
        ))}
        <div className="hero-content__container">
          <div className="hero-content">
            <div>
              <Heading color="white" as="h5" weight="demiBold">
                WE GUARANTEE
              </Heading>
              <Heading align="center" color="white" as="h4">
                The lowest rates and most benefits at the world’s best luxury
                hotels
              </Heading>
            </div>

            <div className="cta">
              <Button onClick={handleJoinNowClick}>Join now</Button>

              {isDesktop && (
                <Link href={navigationPaths.E_GIFT_CARD}>
                  <Button>Buy a gift card</Button>
                </Link>
              )}
            </div>
          </div>

          <Body weight="demiBold" color="white">
            {state.hotelText}
          </Body>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
