import { FC } from 'react';
import Carousel from 'react-material-ui-carousel'
import useLocalised from 'customHooks/useLocalised/useLocalised';
import { TestimonialType } from 'types/types';
import QuotationMark from 'components/icons/QuotationMark'
import ArrowLeft from 'components/icons/ArrowLeft'
import ArrowRight from 'components/icons/ArrowRight'
import { what_our_members_say_about_us } from 'data/translationKeys';
import { TestimonialItem } from 'blocks/SayAboutUs/components/TestimonialItem';
import QuotationMarkHome from "components/icons/QuotationMarkHome";

interface ITestimonials {
  testimonials: TestimonialType[]
};

const SayAboutUs: FC<ITestimonials> = ({
  testimonials,
}) => {
  const what_our_members_say_about_us_text = useLocalised(what_our_members_say_about_us);

  return (
    <div className="say-about-us">
      <div className="say-about-us__title">{what_our_members_say_about_us_text}</div>
      <div className="say-about-us__icon"><QuotationMarkHome /></div>
      <div className='say-about-us__slider'>
        <Carousel
          className='slider__carousel'
          autoPlay={false}
          animation={"slide"}
          navButtonsAlwaysVisible={true}
          NextIcon={
            <div className='slider__carousel__arrow-right'>
              <ArrowRight />
            </div>
          }
          PrevIcon={
            <div className='slider__carousel__arrow-left'>
              <ArrowLeft />
            </div>
          }
          indicators={false}
          height={190}
          duration={400}
          navButtonsProps={{ style: { backgroundColor: 'transparent', borderRadius: 0 } }}
        >
          {testimonials.map((item, i) => <TestimonialItem key={i} item={item} />)}
        </Carousel>
      </div>
    </div>
  );
};

export default SayAboutUs;
